.quota-data{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.quota-data-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap:10px;
    flex-wrap: wrap;
}
.quota-data h3{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0;
    color: #0f044c;
}
.quota-data-item span{
    font-size: 1.1rem;
    font-weight: 600;
    color: rgb(103, 103, 103);
}
.quota-bar{
    width: 200px;
    border: 1px solid #c2c2c2;
    height: 20px;
    border-radius: 7px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.quota-bar-inner{
    height: 18.5px;
    border-radius: 6px;
    background-color: #3A6FF8;
    max-width: 100%;
}

.quota-data-item-list{
    margin-top: 0;
}

.quota-data-item-list li{
    margin: 0 0 20px 0;
}

.quota-app-icon{
    width: 35px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}