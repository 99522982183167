.mail-button {
    background-color: #ffffff;
    color: rgb(56, 11, 161);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 5px #ccc;
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

  }
.mail-button:hover{
    box-shadow: 1px 1px 5px #ccc;
}

.mail-button-inner{
    width: 100%;
  }

.mail-button-text{
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-left: 10px;      
}

.mail-gmail{
    margin-left: -10px;
}
  
  @media only screen and (max-width: 900px) {
    .payment-btn {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .payment-btn {
      width: 170px;
      padding: 9px 40px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .payment-btn {
      width: 100%;
      padding: 9px 40px;
    }

  }
  @media only screen and (max-width: 500px) {
    .mail-button {
        padding: 5px;
        margin: 10px;
        width: 75px;
    }
    .mail-button-text{
        display: none;  
    }
    .mail-gmail{
        margin-left: 0px;
    }
  }