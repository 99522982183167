@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    box-sizing: border-box;
    font-family: 'Open Sans','Source Sans 3','DM Sans','Poppins', sans-serif;
}

html,body{
    margin: 0;
    padding: 0;
}
textarea{
  line-height: 1.7em;
  font-size: 14px;
}
button:disabled, span:disabled{
  cursor:not-allowed !important;
}
.popupCancel, button{
    min-width: max-content;
    
    /* box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
    -webkit-box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
    -moz-box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset; */
}
.popupCancel:active, button:active{
    box-shadow: rgb(0 0 0 / 17%) 0px 23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
    -webkit-box-shadow: rgb(0 0 0 / 17%) 0px 23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
    -moz-box-shadow: rgb(0 0 0 / 17%) 0px 23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
}
.popupCancel:disabled, button:disabled{
    cursor: not-allowed;
  }
.popupCancel{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.play-button{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    border-radius: 50%;
    background-color: #3A6FF8;
    border: none;
    color: #fff;
    font-size: 2.5rem;
    height: 60px;
    width: 60px;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.4s;
    padding-right: 3px;
}
.play-button:hover{
    opacity: 1;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.3s;
}
.ripple:hover {
  background: rgb(255, 255, 255) radial-gradient(circle, transparent 1%, rgb(255, 255, 255) 1%) center/15000%;
}
.ripple:active {
  background-color: #bbb;
  background-size: 100%;
  transition: background 0s;
}







.qmark{
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.qmark2{
  display: unset !important;
  margin-left: 5px;

}
.qmark-div{
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 7px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.20);
}







.video-js{
    border: 1px solid black;
}













.cookieConsent{
	position: fixed;
	bottom: 0;
	z-index: 999;
	display: flex;
	flex-direction: column;
	background-color: #1e213fec;
	color: #ededed;
	transition: 0.4s;
	overflow: auto;
	max-height: 701px;
	width: 100%;
	align-items: center;
}
.cookieConsent p, .cookieConsent h3{
  margin: 0;
}
.cookieConsent-inner-up{
	display:flex;
	align-items: center;
	justify-content: center;
	padding: 25px;
	flex-wrap: wrap;
	gap: 15px;
	max-width: 1530px;
}
.cookie-text{
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 10px;
	flex: 2;
	min-width: 300px;
	text-align: justify;
}
.cookie-text a{
	color: white;
  margin-right: 5px;
}
.cookie-buttons{
	display:flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
	min-width: 300px;
	flex: 1;
}
.cookie-buttons button{
  min-width: max-content;
	border: none;
	border-radius: 7px;
	font-size: 1rem;
	padding: 10px 15px;
	font-weight: bold;
	cursor: pointer;
  flex: 1;
}
.customize{
	color: #151448;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	gap:5px;
}
.arrow-custom{
    content: "";
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid;
    border-top-color: inherit;
}
.accept-all{
	background-color: #3A6FF8;
	color: #fff;
}
.decline-all{
	background-color: #fff;
	color: #151448;
}

.cookieConsent-inner-down{
	display:flex;
	flex-direction: column;
	gap: 15px;
	padding: 15px;
	max-width: 1200px;
	transition: 0.4s;
	max-height: 985px;
	overflow: auto;
}
.cookieConsent-inner-down::-webkit-scrollbar-track {
	background-color: transparent;
  }
.cookie-type{
	padding: 5px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
}
.cookie-type-content{
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.cookie-details{
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding:0 65px;

}
.cookie-heading{
	padding: 5px 15px;
	display: flex;
	align-items: center;
	gap: 5px;
}
.cookie-type input[type='checkbox']{
	height: 20px;
	width: 20px;
}
.saveButton{
	border: none;
	border-radius: 7px;
	font-size: 1rem;
	padding: 10px 15px;
	margin: 15px 25px 25px 25px;
	
	font-weight: bold;
	cursor: pointer;
}








.tutorial-button{
  min-width: max-content;
  max-width: max-content;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.625em 1.1em;
    margin-right: 10px;
    height: 45px;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}




.toogleAll-button{
  border:none;
  background-color: transparent;
  color: #3A6FF8;
  padding: 0;
  text-decoration: underline;
  margin-left: auto;
  cursor: pointer;
}
.toogleAll-button:active{
  box-shadow: none !important;
}






/* toogle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3A6FF8;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .2s;
  }
  
  input:checked + .slider {
    background-color: #3A6FF8;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #3A6FF8;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  
  /* Rounded sliders of switch */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
























/* spinners */

.spinner{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: calc(100vh - 138.88px);
}
.spinner-owner{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: calc(100vh - 232.88px);
}  
.spinner-full{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 216px;
}















/* primary text */
.primary{
    color: #b5b5b5;
    font-weight: 400;
}


















/* scrollbars */

*::-webkit-scrollbar {
    width: 0.4em;
    height: 7px;
}
*::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border-radius: 7px;
}
.side-menu::-webkit-scrollbar {
    border-radius: 0px;
    width: 7px;
}
.side-menu::-webkit-scrollbar-thumb {
    background-color: #ccccccbd;
    border-right: 2px solid #0F044C;
    border-radius: 3px;
    visibility: hidden; 
}
.side-menu::-webkit-scrollbar-track {
    visibility: hidden;
    border-right: 2px solid #0F044C;
}
.side-menu:hover::-webkit-scrollbar-thumb{
    visibility: visible;
}
.side-menu:hover::-webkit-scrollbar-track{
    visibility: visible;
}
.invisible-scroll:hover::-webkit-scrollbar-track{
    visibility: hidden !important;
}
.invisible-scroll:hover::-webkit-scrollbar-thumb{
    visibility: hidden !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.side-menu-applist::-webkit-scrollbar {
    display: none;
}
.side-menu-applist div::-webkit-scrollbar {
    display: none;
}
.language-menu-div::-webkit-scrollbar {
  height: 7px;
}
/* Hide scrollbar for IE, Edge and Firefox */
.side-menu-applist {
    -ms-overflow-style: none;  
    /* IE and Edge */
    scrollbar-width: none;  
    /* Firefox */
}
.side-menu-applist div {
    -ms-overflow-style: none;  
    /* IE and Edge */
    scrollbar-width: none;  
    /* Firefox */
}
@-moz-document url-prefix(){
    *{
        scrollbar-width: thin;
    }
    .collection-buttons{
        scrollbar-color: #f5f5f5 #f5f5f5;

    }
    .collection-buttons:hover{
        scrollbar-color: #d4d4d4 #f5f5f5;
    }
    .side-menu{
        scrollbar-color: #5b5b7b #0F044C;

    }
}





















/* swal css */
.swal2-title {
  padding: 0.8em 1em 0.4em !important;
}

.swal2-html-container{
    font-size: 0.9rem !important;
    line-height: 25px !important;
    margin: 0 1.6em 0 !important;
}
/* .swal2-timer-progress-bar{
    background: rgb(0 0 0 / 50%) !important;
} */
.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}
.swal2-popup{
  border-radius: 15px !important;
}
.swal2-styled.swal2-confirm {
    border-radius: 15px !important;
    font-size: 1rem;
    padding: 0.625em 1.1em;
    width: 96px !important;
    height: 45px !important;
}
.swal2-styled.swal2-cancel {
  border-radius: 15px !important;
  font-size: 1rem;
  width: 96px !important;
  height: 45px !important;
  padding: 0.625em 1.1em;
}
.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}
.swal2-styled.swal2-cancel:focus {
    box-shadow: none !important;
}
.swal2-icon.swal2-error {
    border-color: #fff !important;
    color: #fff !important;
    background-color: #cf000f !important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line]{
    background-color: #fff !important;
}






















/* drag drop upload */
.drag-file-element{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    backdrop-filter: blur(2px);
    /* backdrop-filter: blur(1px); */
}
.drag-file-empty{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    text-align: center;
    font-weight: 100;
    color: #666;

    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;
}






















/* upload buttons */
  .singular-upload-div{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }


  .upload-singular{
    color: white;
    border-radius: 10px;
    background-color: #71c456;
    border: none;
    cursor:pointer;
    font-size: 1rem;
    padding: 6px 10px;
    width: max-content;
    /* margin-right: 35px; */


  }
  .upload-singular:hover{
    background-color: #3A6FF8e6;
  }
  .upload-singular:active{
    background-color: #3A6FF8;
  }
  .upload-singular:disabled{
    cursor: not-allowed;
  }



















/* language divs */
  .language-menu-div{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    top: 0;
    /* left: 100%; */
    z-index: 4;
    background-color: white;
    /* border-radius: 7px; */
    padding: 18px 25px;
    /* margin: 15px 0; */
    /* width: calc(100% + 50px); */
    /* flex-wrap: wrap; */
    margin-left: -25px;

    /* border-bottom: 1px solid #ddd; */

    /* overflow: hidden; */
    min-width: 100%;

    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px 0px; */
}

.language-menu-div-inner{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-left: 35px;
  
}

.language-menu-language{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-left: auto; */
  z-index: 5;
  position: fixed;
  right: 40px;
  top: 20px;
}



.delete-collection{
    color: white;
    border-radius: 10px;
    background-color: #ff0000;
    border: none;
    cursor:pointer;
    font-size: 0.85rem;
    width: 70px;
    padding: 0 15px;
    height: 36px;
  }
  .delete-collection:hover{
    background-color: #fe0000c6;
  }
  .delete-collection:active{
    background-color: rgb(255, 0, 0);
  }
  .delete-collection:disabled{
    cursor: not-allowed;
  }


  .col-menu-button{
    color: white;
    padding: 9px 17px;
    background: #3A6FF8;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: none;
    cursor:pointer;
    font-size: 0.85rem;
    width: 70px;
    height: 36px;

  }
  .col-menu-button:hover{
    background-color: #3A6FF8e6;
  }
  .col-menu-button:active{
    background-color: #3A6FF8;
  }
  .col-menu-button:disabled{
    cursor: not-allowed;
  }

  .col-menu-button-ex{
    color: white;
    padding: 9px 17px;
    background: #F3802D;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: none;
    cursor:pointer;
    font-size: 0.85rem;
    width: 70px;
    height: 36px;

  }
  .col-menu-button-ex:hover{
    background-color: #F3802De6;
  }
  .col-menu-button-ex:active{
    background-color: #F3802D;
  }
  .col-menu-button-ex:disabled{
    cursor: not-allowed;
  }


  .col-menu-upload{
    color: white;
    padding: 9px 17px;
    border-radius: 10px;
    /* background-color: #3A6FF8; */
    border: none;
    cursor:pointer;
    font-size: 0.85rem;
    width: 70px;
    height: 36px;
    background: #0F044C;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

  }
  .col-menu-upload:hover{
    background-color: #0F044Ce6;
  }
  .col-menu-upload:active{
    background-color: #0F044C;
  }
  .col-menu-upload:disabled{
    cursor: not-allowed;
  }

  .divider-menu{
    color:#aaa;
    height: 30px;
    width: 1px;
    border-left: 1.5px solid #ddd;
  }












.current-inner-container{
    /* padding-top:25px !important; */
    margin-top: 35px !important;
}
















.country-name{
  line-height: 0;
  font-weight: bold;
  color: #0F044C;
  font-size: 18px;
}
.character-count{
  font-weight: 500;
  font-size:14px;
  line-height: 26px;
  color: #676767
} 
.country-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.country-flag-name{
  display: flex;
  align-items: center;
  gap:10px;
  /* background-color:#F2F2F2 ;
  padding: 0 5px 0 5px;
  border-radius: 7px; */
}






















.edit-warning{
  background: #fff2ea;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  /* margin-top: 15px; */

  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
}
.resposive-edit-warning-inner{
  display: none;
}

/* @media only screen and (max-width: 726px){
  .language-menu-div-inner{
    flex-wrap: wrap;
  }
  .edit-warning-inner{
    display: none;
  }
  .resposive-edit-warning-inner{
    font-size: 13px;
    display: block;
  }
  .edit-warning img{
    margin-right: 0 !important;
  }
} */



.sub-warning{
  background: #FFF2F2;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  height: 36px;
  /* margin-top: 15px; */

  display: flex;
  /* justify-content: center; */
  align-items: center;
  max-width: max-content;
  height: max-content;
  
}
.sub-warning2{
  background: #FFF2F2;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  height: 36px;
  /* margin-top: 15px; */

  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* max-width: max-content; */
  height: max-content;
  /* margin-left: 35px; */
  margin-bottom: 5px;
  
}

.sub-message{
  background: #FFF2F2;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  /* margin-top: 15px; */

  display: flex;
  /* justify-content: center; */
  align-items: center;
  max-width: max-content;
  height: max-content;
}