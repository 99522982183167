.appInfo-team-list{
    display: flex;
    flex-wrap: wrap;
    gap:24px;
}
.appInfo-team-member{
    position: relative;
    padding: 30px 35px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    width: 284px;
    height: 284px;
    /* border: 1px solid #bfbfbf; */
    border-radius: 15px;

    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow:0px 2px 6px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);  */

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.20);

   
}
.member-avatar{
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2rem;
}
.member-name{
    font-weight: 700;
    line-height: 23px;
}
.member-title{
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 28px;
}
.member{
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
    overflow-wrap: break-word;
    /* margin-bottom: 5px; */
}

.three-dot-menu-icon{
    position: absolute;
    display: flex;
    top: 10px;
    align-items: center;
    right: 5px;
    justify-content: center;
    cursor: pointer;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}
.three-dot-menu-icon>img{
    height: 25px;
    width: 35px;
    opacity: 0.85;
    border-radius: 50%;
}
.three-dot-menu{
    display: none;
    position: absolute;
    top:40px;
    right: 22px;
    border-radius: 7px;
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.30);
    /* box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%); */
}
.three-dot-menu-open{
    display: block !important;
}
.memberCustomize{
    font-size: 0.9rem;
    padding: 10px;
    cursor: pointer;
}
.member-edit{
    border-bottom:1px solid #dfdfdf;
    border-radius:7px 7px 0 0;
    /* color:#3A6FF8; */
}
.member-edit:hover{
    background-color: #eee;
}
.member-delete{
    border-radius:0;
    border-bottom:1px solid #dfdfdf;
    /* color:red; */
}
.member-delete:hover{
    background-color: #eee;
}
.member-transfer{
    border-radius:0 0 7px 7px;
    border:none
}
.member-transfer:hover{
    background-color: #eee;
}

.del{
    background-color: #0F044C !important;
}
.del:hover{
    background-color: #0f044ce6 !important;
}
.del:active{
    background-color: #0F044C !important;
}
  

/* @media only screen and (max-width: 768px){
    .appInfo-team-member{
        width: 48%;
  }
  } */
@media only screen and (max-width: 635px){
    .appInfo-team-member{
        width: 100%;
  }
  }
/* .edit-delete-member{
    position: absolute;
    top:10px;
    right:10px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.edit-member>img{
    height: auto;
    width: 15px;
    margin-right: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.delete-member>img{
    height: auto;
    width: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.seperate-member{
    color:#ccc;
    margin-right: 5px;
} */