.current-owner-local-item{
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}
.current-owner-local-item:hover{
    background-color: #f5f5f5;
}
.current-owner-local-item-disabled{
    transition: 0.4s;
    padding: 15px 15px;
    color:grey;
    cursor: default;
}


.current-owner-local-item-not{
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}
.current-owner-local-item-not:hover{
    background-color: #f5f5f5;
}
.current-owner-local-item-not-disabled{
    transition: 0.4s;
    padding: 15px 15px;
    color:grey;
    cursor: default;
}


.current-owner-primary{
    color: #b5b5b5;
    font-weight: 400;
    
}

.delete-language{
    color: #fff;
    border-radius: 50%;
    background-color: white;
    height: 15px;
    width: 15px;
    display: none;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-right: 15px;

}
.current-owner-local-item:hover .delete-language{
    display: flex;
}

.add-language{
    color: #fff;
    border-radius: 50%;
    background-color: white;
    height: 15px;
    width: 15px;
    display: none;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
    

}
.current-owner-local-item-not:hover .add-language{
    display: flex;
}