.payment-container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px;
}

.payment-content{
    width: 600px;
    background-color: white;
    border-radius: 7px;
    
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
.payment-name{
    font-size: 2rem;
    font-weight: bold;
    color:#0f044c
}
.payment-text{
    font-weight: 700;
    font-size: 16.5px;
    line-height: 28px;
    color: #676767;
}
.payment-button{
    color: white;
    background-color: #3A6FF8;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.625em 1.1em;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

