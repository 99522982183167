.add-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 51;
    

    display: none;
    align-items: center;
    justify-content: center;
}
.addPopupBack{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000066;
    height: 100%;
    width: 100%;
    z-index: 52;

}
.add-popup-content{
    width: 600px;
    height: 500px;
    background-color: white;
    border-radius: 7px;
    position: relative;
    

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
.add-popup-name{
    font-size: 1.5rem;
    color:#0f044c;
    font-weight: bold;
}
.create-app-form{
    width: 80%;
}
.add-popup-form{
    width: 100%;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 10px;
}
.addPopupButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-popup-button{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    margin-right: 10px;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.add-popup-button2{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    text-align: center;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.add-popup-button:hover{
    background-color: #305dd1;
}
.add-popup-button:active{
    background-color: #0083ce;
}
.add-popup-button2:hover{
    background-color: #dc7429;
}
.add-popup-button2:active{
    background-color: #4f565c;
}


/* Chrome, Safari, Edge, Opera */
.add-popup-form::-webkit-outer-spin-button,
.add-popup-form::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.add-popup-form[type=number] {
  -moz-appearance: textfield;
}

#addAppPopupForm4{
    resize: none;
}
