.screenshot-local-item{
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    align-items:center ;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}
.screenshot-local-item:hover{
    background-color: #f5f5f5;
}

.screenshot-local-item:hover .delete-language{
    display: flex;
}
.screenshot-local-item-disabled{
    transition: 0.4s;
    padding: 15px 15px;
    color:grey;
    cursor: default;
}



.screenshot-local-item-not{
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    align-items:center ;
}
.screenshot-local-item-not:hover{
    background-color: #f5f5f5;
}

.screenshot-local-item-not:hover .add-language{
    display: flex;
}
.screenshot-local-item-not-disabled{
    transition: 0.4s;
    padding: 15px 15px;
    color:grey;
    cursor: default;
}