.collection-screenshot-item{
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
    /* background-color: #f5f5f5; */
}

.collection-screenshot-device-name{
    /* margin-bottom: 15px; */
    /* font-weight: bold; */
    line-height: 1;
    /* margin: 1em 0 5px 0; */
    cursor: pointer;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px;
    border-radius: 7px;
    /* border: 1px solid #ddd; */
    user-select: none;
}
.unhid{
    display: flex !important;
}
.hidden{
    display: none;
}
.rotate{
    transform: rotateX(180deg);
}
.collection-screenshot-icon{
    width: 15px;
    height: 15px;
    margin: 0 15px;
    transition:transform 0.2s ease-in-out;
}
.collection-previews-screenshots{
    display: none;
    flex-direction: column;
    /* background-color: #fff; */
    /* overflow:overlay; */
    border-radius: 7px;
}
.collection-preview-set{
    margin: 15px 0;
    padding: 25px 25px;
    display: flex;
    flex-direction: column;
    background-color: white;
    /* min-width: 190px; */
    /* border-right: 1px solid #d5d5d5; */
    border-radius: 10px;
}
.collection-screenshot-set{
    padding: 25px 25px;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    flex: auto;
    background-color: white;
    border-radius: 10px;
}
.collection-preview-set-info{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
    min-width: max-content;
}
.collection-screenshot-set-info{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
    min-width: max-content;
}
.additional-collection-info{
    font-weight: 100;
    font-size: 0.9rem;
}
.if-collection-set-from-primay{
    justify-content: space-between;
}
.collection-row{
    position: relative;
    min-height: 226px;
    display: flex;
    min-width: 160px;
    /* flex-wrap: wrap; */
    /* overflow: auto; */
}
.collection-col{
    margin-right: 22px;
    position:relative;
    padding: 3px 0 0 3px;
}
.collection-col-list{
    display: flex;
    /* flex-wrap: wrap; */
    list-style-type: none;
    margin: 0;
    /* overflow: auto; */
    padding: 0;
}
.collection-col-list-preview{
    display: flex;
    /* flex-wrap: wrap; */
    list-style-type: none;
    margin: 0;
    /* overflow: auto; */
    padding: 0;
}


.delete-screenshot{
    width: 15px;
    height: 15px;
    background-color: white;
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    transform: translate(-20%, -20%);
    /* transition: scale 0.3s linear; */
    
    /* visibility: hidden; */
    display: none;
    justify-content: center;
    align-items: center;
    color: white;
    /* opacity: 0.8; */
    cursor: pointer;
    /* transition: 0.4s; */
}
/* .delete-screenshot:hover{
    opacity: 1;
} */
.delete-preview{
    width: 15px;
    height: 15px;
    background-color: white;
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    transform: translate(-20%, -20%);
    /* transition: scale 0.3s linear; */
    
    /* visibility: hidden; */
    display: none;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    /* transition: 0.4s; */
    /* opacity: 0.8; */
}
/* .delete-preview:hover{
    opacity: 1;
    
} */



.missing-collection{
    opacity: 0.5;
    position: relative;
}



.collection-screenshots{
    height: 216.43px;
    border-radius: 7px;
    transition: 0.4s;
    cursor: pointer !important;
    box-shadow: 0px 2px 6px 0px rgba(191, 191, 191, 0.7);
}
.collection-screenshots:hover{
    opacity: 0.6;
    cursor: grab;
}



.collection-previews{
    height: 216.43px;
    border-radius: 7px;
    box-shadow: 0px 2px 6px 0px rgba(191, 191, 191, 0.7);
    transition: 0.4s;
}


.collection-screenshots-ipad{
    height: 216.43px;
    border-radius: 7px;
    cursor: pointer !important;
    transition: 0.4s;
    box-shadow: 0px 2px 6px 0px rgba(191, 191, 191, 0.7);
}
.collection-screenshots-ipad:hover{
    opacity: 0.6;
    cursor: grab;
}


.collection-previews-ipad{
    height: 216.43px;
    border-radius: 7px;
    box-shadow: 0px 2px 6px 0px rgba(191, 191, 191, 0.7);
    transition: 0.4s;
}


.collection-screenshot-edit{
    /* margin-top: 15px; */
    padding: 0 20px 15px 15px;
    /* display: none; */
}
.collection-screenshot-upload-label{
    font-size: 0.85rem;
    font-weight: 500;
    color: #3A6FF8;
    cursor: pointer;
}
.collection-screenshot-upload-label:hover{
    text-decoration: underline;
}
.edit-seperator{
    color:#c5c5c5;
    font-weight: 100;
    cursor: default;
}
.collection-screenshot-delete-label{
    font-size: 0.85rem;
    font-weight: 500;
    color: #0f044c;
    cursor: pointer;
}
.collection-screenshot-delete-label:hover{
    text-decoration: underline;
}
.no-display{
    display: none;
}





.collection-modal {
    display: none;
    position: fixed;
    z-index:50;
    padding: 10px 62px 0px 62px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000000b3;
}
.collection-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0 0 0 0;
    width: 80%;
    max-width: 1200px;
    min-height: 90vh;
}
.collection-screenshot-slide-device-name{
    text-align: center;
    color: #fff;
}

.collection-screenshots-slide{
    height: auto;
    width: 296px;
    border-radius: 7px;
    margin: 0 auto;
}
.collection-slide-style{
    text-align: center;
}
.collection-close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 4rem;
    font-weight: bold;
}
.collection-close:hover,
.collection-close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}
.collection-previous,
.collection-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 35px;
  transition: 0.4s ease;
  border-radius: 7px;
  user-select: none;
  -webkit-user-select: none;
}

.collection-next {
  right: 20%;
}
.collection-previous {
  left: 20%;
}

.collection-previous:hover,
.collection-next:hover {
  background-color: rgb(126, 126, 126);
}
.collection-previous:active,
.collection-next:active {
  background-color: #3A6FF8;
}


.collection-video-div{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 55;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000000b3;

}
.collection-video-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 7px;
    width: 30vw;
    height: 80vh;
    max-height: 750px;
    max-width: 750px;
    min-width: 350px;
    /* min-height: 350px; */
    padding:35px;
    /* gap:15px; */
}
.video-buttons{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
.video-buttons span{
    cursor: pointer;
    user-select: none;
    color: #3A6FF8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.set-poster{
    color: #fff;
    border: none;
    border-radius: 7px;
    background-color: #3A6FF8;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1rem;
    min-width: 155px;
}
.poster-check{
    display: flex;
    width: 100%;
    align-items: center;    
    font-size: 0.9rem;
}
.poster-check label{
    display: flex;
    align-items: center;    
    justify-content: center;
    margin-top: 5px;
    margin-left: -4px;
 
}
.poster-check input[type="checkbox"]{
    height: 15px;
    width: 15px;
 
}