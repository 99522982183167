.help-title-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:15px;
    flex-wrap: wrap;
    background-color: white;
    padding: 25px 50px 25px 35px;
    color:#0f044c
}
.help-title-div h1{
    margin:0;
    font-size: 24px;
}
.help-content{
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
}
.help-content h2{
    color: #0f044c;
    font-size: 1.6rem;
}
.help-inner-container{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    gap:35px;
    /* flex-wrap: wrap; */
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 119.88px);
    padding: 35px;
    overflow: auto;
}
.help-contact{
    display: flex;
    justify-content: center;
    align-items: center;
}
.help-contact-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    max-width: 1200px;
    width: 100%;
    flex-wrap: wrap;
}
.help-contact-div{
    flex:1;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 15px;
    gap: 15px;
    min-width: 360px;
}
.help-contact-div img{
    width: auto;
    height: 50px;
}
.help-contact-div a{
    text-decoration: none;
    color: #fff;
    background-color: #3A6FF8;
    padding: 10px 20px;
    border-radius: 15px;
    line-height: 28px;
    width: 250px;
    text-align: center;
    transition: 0.4s;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.help-contact-div a:hover{
    background-color: #F3802D;
}
.help-contact-div p{
    font-weight: 700;
    font-size: 16.5px;
    line-height: 28px;
    color: #676767;
}

.help-form{
    display: flex;
    justify-content: center;
    align-items: center;

}
.help-form form{
    display: flex;
    justify-content: center;

    flex-direction: column;
    padding: 35px;
    background-color: #fff;
    max-width: 1200px;
    width: 100%;
    border-radius: 15px;
    gap: 25px;
}
.help-form form input{
    border-radius: 6px;
    background-color: #f2f2f2;
    height: 46px;
    width: 100%;
    outline: none;
    border: none;
    padding: 20px 20px;
}
.help-form form textarea{
    border-radius: 6px;
    background-color: #f2f2f2;
    resize: none;
    width: 100%;
    outline: none;
    border: none;
    padding: 20px 20px;
}
.help-form form button{
    text-decoration: none;
    color: #fff;
    background-color: #3A6FF8;
    padding: 10px 20px;
    border-radius: 15px;
    line-height: 28px;
    font-size: 1rem;
    text-align: center;
    transition: 0.4s;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.help-links{
    display: flex;
    justify-content: center;
    align-items: center;

}
.help-links a{
    margin-right: 10px;
    text-decoration: none;
    color:#3A6FF8;
}