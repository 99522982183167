.sidebar-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.si{
    transition: 0.4s;
}
.si:hover{
    background-color: #181859;
}
/* .si:hover .active-url-indicator{
    opacity: 1;   
} */
.app-icon{
    border-radius: 7px;
    margin: 28px 22px;
    width: 42px;
    height: auto;
}
.app-name{
    color:white;
    font-size: 14px;
    font-weight: 700;
}
#sideMenu .app-name{
    min-width: max-content;
}
.bgs{
    transition: 0.4s;
}
.bgs:hover{
    background-color: #181859;

}
