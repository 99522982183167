

.owner-collection-buttons{
    display: flex;
    align-items: center; 
    overflow: auto;  
}
.owner-collection-select{
    /* padding: 0 35px 0px 35px; */
    width: 100%;
    /* max-width: 1350px; */
    /* height: 100%; */
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    margin-top: 25px;
    gap: 35px;
    
}
.owner-collection{
    width: 45%;
    max-width: 600px;
    min-width: 330px;
    height: 321px;
    border-radius: 10px;
    /* box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.04); */
    /* border: solid 1px #ececec; */
    margin: 12px 12px 12px 12px;
    padding: 17px 23px 24px 24px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    /* height: 365px; */

}


.owner-collection-name{
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}
.owner-golive{
  display: flex;
  text-align: center;
  position: relative;
}
.owner-golive-button{
    color: white;
    background: #F3802D;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: none;
    cursor:pointer;
    font-size: 0.85rem;
    padding: 5px 10px;
    width: 80px;
    height: 33px;
    /* margin-top: 15px; */

  }
  .owner-golive-button:hover{
    background-color: #F3802De6;
  }
  .owner-golive-button:active{
    background-color: #F3802D;
  }
  .owner-golive-button:disabled{
    cursor: not-allowed;
  }
  .owner-golive-live{
    color: white;
    background: #3A6FF8;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: none;
    font-size: 0.85rem;
    padding: 5px 10px;
    width: max-content;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .three-dot-menu-iconX{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: -15px;
    margin-left: 5px;
  }
.three-dot-menu-iconX>img{
    height: 25px;
    width: 35px;
    opacity: 0.85;
    border-radius: 50%;
}
.three-dot-menuX{
    display: none;
    position: absolute;
    top:40px;
    right:-62px;
    border-radius: 7px;
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.three-dot-menu-openX{
    display: block !important;
}
.memberCustomizeX{
    font-size: 0.9rem;
    padding: 10px;
    cursor: pointer;
}
.member-editX{
    border-bottom:1px solid #dfdfdf;
    border-radius:7px 7px 0 0;
    /* color:#3A6FF8; */
}
.member-editY{
  border-bottom:1px solid #dfdfdf;
  /* color:#3A6FF8; */
}
.member-editZ{
  border-bottom:1px solid #dfdfdf;
  /* color:#3A6FF8; */
  border-radius:0 0 7px 7px ;
}
.member-editX:hover, .member-editY:hover, .member-editZ:hover{
    background-color: #eee;
}
.member-deleteX{
    border-radius:0 0 7px 7px;
    border-bottom:none;
    /* color:red; */
}
.member-deleteX:hover{
    background-color: #eee;
}


  .owner-collection-shots{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 72%;
    width: 100%;
    gap: 6px;
    overflow: auto;
    font-weight: 600;
    font-size: 1rem;
    line-height: 23px;
    text-align: center;
  }
  .collection-shot{
   border-radius: 9px;
    /* border: solid 1px #cdcccc; */
    width: auto;
    /* max-width: 95px; */
    height: 86%;
    margin-right: 1px;
    box-shadow: 0px 2px 6px 0px hsla(0, 0%, 75%, 0.7);

  }



  .newCol{
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;


    /* width: 45%; */
    /* max-width: 600px; */
    /* min-width: 330px; */
    /* height: 315; */
    border-radius: 10px;
    /* box-shadow: 0 0 9px 0 rgb(0 0 0 / 4%); */
    /* border: solid 1px #ececec; */
    margin: 12px 12px 12px 12px;
    /* padding: 17px 23px 24px 24px; */
    /* background-color: #fff; */
  
    flex-direction: column;
    
    position: relative;
    
  }
  .newCol2{
    display: flex;
    justify-content: center;
    align-items: center;
  }







  @media only screen and (min-width: 1600px) {
    .owner-collection{
        height: 355px;
    }

  }


  @media only screen and (max-width: 1425px) {
    .owner-collection{
        height: 300px;
    }

  }

  @media only screen and (max-width: 1375px) {
    .owner-collection{
        height: 250px;
    }

  }
  @media only screen and (min-width: 1375px) and (max-width:1425px){
    .owner-collection{
      max-width: 430px;
    }
  }


  @media only screen and (max-width: 1225px) {
    .owner-collection{
        width: 100%;
        height: 380px;
    }
    .owner-collection-shots{
        /* height: 80%; */
      }
  }

  @media only screen and (max-width: 1080px) {
    .owner-collection{
        /* height: 330px; */
    }
    .owner-collection-shots{
        /* height: 75%; */
      }
  }
  @media only screen and (max-width: 970px) {
    .owner-collection{
        /* height: 300px; */
    }
    .owner-collection-shots{
        /* height: 70%; */
      }
  }
  @media only screen and (max-width: 900px) {
    .owner-collection{
        /* height: 270px; */
    }
    .owner-collection-shots{
        /* height: 65%; */
      }
  }
  @media only screen and (max-width: 830px) {
    .owner-collection{
        height: 400px;
    }
    .owner-collection-shots{
        /* height: 60%; */
      }
  }
