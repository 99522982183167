.owner-content{
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
}
.owner-title-div{
    position: relative;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    background-color:white;
    padding: 7px 50px 7px 35px;
    /* border-bottom: 1px solid  #dedede; */
    gap:15px;color: #0F044C;

}
.owner-title-div h1{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
}
.owner-inner-container{
    display: flex;
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    flex-direction: column;
  
    max-height: calc(100vh - 35.88px);
}
/* @media only screen and (max-width: 1024px)  {
    .owner-inner-container{
        max-height: none;
    }
} */
.owner-inner-content{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    width: 100%;
    /* margin: 35px; */
    overflow-y: auto;
    padding: 0 25px 25px 25px;
    /* border: 1px solid #dedede; */
    border-radius: 10px;
    position: relative;
    overflow-x: hidden;
    align-items: center;
}
.owner-inner-content-col{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    width: 100%;
    /* margin: 35px; */
    overflow-y: auto;
    padding: 0 40px 40px 40px;
    /* border: 1px solid #dedede; */
    border-radius: 10px;
    position: relative;
    overflow-x: hidden;
    /* align-items: center;*/
}

@media only screen and (max-width: 402px){
    .owner-inner-content{
      margin: 15px 0;
  }
  }