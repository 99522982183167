.three-dot-col-menu{
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    min-width: 160.5px;
    background-color: white;
    /* box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%); */
    z-index: 5;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}
.three-dot-col-menu-open{
    display: flex !important;
    border-radius: 0 0 10px 10px;
    line-height: 13px;
}
.colCustomize{
    font-size: 0.80rem;
    padding: 15px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}
.colCustomize:hover{
    background-color: #eee;
}
.three-dot-col-menu-rename{
    border-bottom:1px solid #dfdfdf;
    /* border-radius:7px 7px 0 0; */
    /* color:#3A6FF8; */
}
.three-dot-col-menu-export, .three-dot-col-menu-import{
    border-bottom:1px solid #dfdfdf;
    color:#3A6FF8;
}

.three-dot-col-menu-delete{
    border-radius:0 0 7px 7px;
    border-bottom:none;
    /* color:red; */
}




.my-actions { margin: 1em 3em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }

.right-gap {
  margin-right: auto;
}