.name-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 51;
    

    display: none;
    align-items: center;
    justify-content: center;
}
.for_owner{
    display: flex !important;
}
.changeNamePopupBack{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000066;
    height: 100%;
    width: 100%;
    z-index: 52;

}
.name-popup-content{
    width: 600px;
    height: 300px;
    background-color: white;
    border-radius: 7px;
    position: relative;
    

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
.name-popup-name{
    font-size: 1.5rem;
    color:#0f044c;
    font-weight: bold;
}
.change-name-form{
    width: 80%;
}
.name-popup-form{
    width: 100%;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 7px;
    padding: 10px;
}
.changeNamePopupButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.name-popup-button{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    margin-right: 10px;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.name-popup-button2{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    text-align: center;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.name-popup-button:hover{
    background-color: #305dd1;
}
.name-popup-button:active{
    background-color: #305dd1;
}
.name-popup-button2:hover{
    background-color: #dc7429;
}
.name-popup-button2:active{
    background-color: #4f565c;
}