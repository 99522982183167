.description-item{
    padding-bottom: 20px;
}
.description-textarea{
    background-color: #fff;
    border: none;
    border-radius: 7px;
    outline: none;
    padding: 20px 30px;
    max-height: 300px;
    max-width:100%;
    min-width:100%;
    overflow: auto;
}
