.current-screenshot-item{
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
}
.current-screenshot-device-name{
    line-height: 1;
    /* margin: 1em 0 0 0; */
    cursor: pointer;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px;
    border-radius: 7px;
    /* border: 1px solid #ddd;*/
}
.device-title p{
    font-size: 19px;
    line-height: 31px;
    margin: 0;
}
.current-previews-screenshots{
    display: flex;
    flex-direction: row;
    /* background-color: #fff; */
    overflow: overlay;
    gap: 5px;
    /* border-radius: 7px;*/
}
.current-screenshot-set{
    padding: 25px 25px;
    margin: 15px 0;
    display: none;
    flex-direction: column;
    flex: auto;
    background-color: white;
    border-radius: 10px;
}
.current-preview-set{
    margin: 15px 0;
    padding: 25px 25px;
    display: none;
    flex-direction: column;
    background-color: white;
    /* min-width: 190px; */
    /* border-right: 1px solid #d5d5d5; */
    border-radius: 10px;
}
.current-unhid{
    display: flex !important;
}
.current-hidden{
    display: none;
}
.current-rotate{
    transform: rotateX(180deg);
}
.current-screenshot-icon{
    width: 15px;
    height: 15px;
    margin: 0 15px;
    transition:transform 0.2s ease-in-out;
}
.current-screenshot-set-info{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
}
.current-preview-set-info{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
}
.additional-info{
    font-weight: 100;
    font-size: 0.9rem;
    min-width: max-content;
}
.if-set-from-primay{
    justify-content: space-between;
}


.row{
    position: relative;
    min-height: 226px;
    min-width: 160px;
    display: flex;
    /* flex-wrap: wrap; */
    list-style-type: none;
    padding:0;
    margin:0;
    /* overflow: auto; */
}
.col{
    margin-right: 22px;
    position:relative;
    padding: 3px 0 0 3px;
}
.col-list{
    display: flex;
    /* flex-wrap: wrap; */
    list-style-type: none;
    margin: 0;
    /* overflow: auto; */
    padding: 0;
}
.col-list-preview{
    display: flex;
    /* flex-wrap: wrap; */
    list-style-type: none;
    margin: 0;
    /* overflow: auto; */
    padding: 0;
}
.missing-current{
    opacity: 0.5;
    position: relative;
}
.current-screenshots{
    height: 216.43px;
    border-radius: 7px;
    transition: 0.4s;
    cursor: pointer !important;
    box-shadow: 0px 2px 6px 0px rgba(191, 191, 191, 0.7);
}
.current-previews{
    height: 216.43px;
    border-radius: 7px;
    transition: 0.4s;
    box-shadow: 0px 2px 6px 0px rgba(191, 191, 191, 0.7);
}
.current-screenshots:hover{
    opacity: 0.6;
    cursor: grab;
}
.current-screenshots-ipad{
    height: 216.43px;
    border-radius: 7px;
    cursor: pointer !important;
    transition: 0.4s;
    box-shadow: 0px 2px 6px 0px #c0c0c0ed;
}
.current-screenshots-ipad:hover{
    opacity: 0.6;
    cursor: grab;
}
.current-previews-ipad{
    height: 216.43px;
    border-radius: 7px;
    transition: 0.4s;
    box-shadow: 0px 2px 6px 0px #c0c0c0ed;
}
.current-previews-ipad:hover{
    opacity: 0.6;
    cursor: grab;
}
.current-screenshot-edit{
    margin-top: 15px;
}
.current-screenshot-upload-label{
    font-size: 0.9rem;
    color: #3A6FF8;
    cursor: pointer;
}
.current-screenshot-upload-label:hover{
    text-decoration: underline;
}
.edit-seperator{
    color:#c5c5c5;
    font-weight: 100;
    cursor: default;
}
.current-screenshot-delete-label{
    font-size: 0.9rem;
    color: red;
    cursor: pointer;
}
.current-screenshot-delete-label:hover{
    text-decoration: underline;
}
.no-display{
    display: none;
}






.modal {
    display: none;
    position: fixed;
    z-index:50;
    padding: 10px 62px 0px 62px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000000b3;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin: auto;
    padding: 0 0 0 0;
    width: 80%;
    max-width: 1200px;
    min-height: 90vh;
}
.current-screenshot-slide-device-name{
    text-align: center;
    color: #fff;
}

.current-screenshots-slide{
    height: 100%;
    border-radius: 7px;
    margin: 0 auto;
}
.slide-style{
    text-align: center;
}
.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 4rem;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}
.previous,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 35px;
  transition: 0.4s ease;
  border-radius: 7px;
  user-select: none;
  -webkit-user-select: none;
}

.next {
  right: 20%;
}
.previous {
  left: 20%;
}

.previous:hover,
.next:hover {
  background-color: rgb(126, 126, 126);
}
.previous:active,
.next:active {
  background-color: #3A6FF8;
}

.video-div{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 55;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000000b3;

}
.video-inner{
    display: flex;
    justify-content: center;
    align-items: center;
}
