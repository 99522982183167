.side-menu{
    position: fixed;
    top:0;
    left:0;
    background-color: #0F044C;
    width: 360px;
    min-width: 360px;
    height: 100%;
    overflow-y: auto;
    overflow: auto;
    overflow: overlay;
    overflow-x: hidden;
    z-index: 49;
    transition: 0.4s;
    
}

.logo{
    display: flex !important;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: 0.4s;
    padding: 36px 22px;
}
.logo img {
    display: none;
    height: 50px;
}


.logo p{
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #fff;
}

.toogle-sidebar{
    cursor: pointer;
    display: block;
    width: 20px; 
    left: 360px;
    top:82%;
    height: 54px;
    border-radius: 0 7px 7px 0;
    background-color: #0F044C;
    border: none;
    transition: 0.4s;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0;
}
.select-app{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: 0.4s;
    background-color: #0F044C;
    /* border-bottom: 1px solid #3A6FF8; */
    /* background-color: #00003d; */

}
/* .select-app:hover{
    background-color: #181859;
} */
.selected-app-icon{
    border-radius: 7px;
    margin: 28px 22px;
    width: 42px;
    height: 42px;
}
.list-app-icon{
    border-radius: 7px;
    margin: 14px 22px;
    width: 42px;
    height: 42px;
}
.add-app-icon{
    border-radius: 7px;
    margin: 14px 22px;
    width: 42px;
    height: 42px;
}
.selected-app-name{
    color:white;
    font-size: 15.5px;
    font-weight: 700;
    flex-grow: 1;
}
#sideMenu .selected-app-name{
    min-width: max-content;
}
.select-app-button{
    margin-right: 15px;
    width: 25px;
    transition: transform 0.45s ease-in-out;
    
}
.side-menu-applist{
    max-height: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    transition: max-height 0.45s ease-in-out;
    background-color: #1d1d70
}

.visible{
    max-height: 350px;
}
.rotate-icon{
    transform : rotateX(180deg);
}
.add-app{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor:pointer;
    transition: 0.4s;
    background-color: #0F044C;
    /* border-top: 1px solid #464764; */
    /* border-bottom: 1px solid #464764; */
}
.add-app:hover{
    background-color: #181859;
}
.add-app-name{
    flex-grow: 1;
    color:white;
    font-weight: 700;
    font-size: 18px;
    min-width: max-content;
}
.add-app-button{
    padding: 0 15px 0 0;
}
.sidebar-sub{
    /* letter-spacing: 0.5px; */
    transition: 0.4s;
}
.sidebar-sub p{
    font-size: 14px;
    font-weight: 400;
    line-height: 18.5px;
    color:#B0B0B0;
    margin-right: 20px;
    margin-left: 85px;
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: max-content;
}
.sidebar-sub h3{
    padding-left: 40px;
    padding-top: 20px;
    font-weight: 700;
    font-size: 18.5px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 10px;
    min-width: max-content;
}

.sidebar-sub a{
    text-decoration: none;
}
.sidebar-sub-item{
    display: flex;
    align-items: center;
    color:white;
    cursor: pointer;
    position: relative;
}
.disabled-sidebar-sub-item{
    opacity: 0.5;
    cursor: default;
}

.active-url, .passive-url{
    /* display: none; */
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.4s, opacity 0.4s linear;
}
.sidebar-sub-item img{
    margin-right: 20px;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: absolute;
}
.ssi{
    transition: 0.4s;
}
.ssi .passive-url{
    /* display: block; */
    visibility: visible;
    opacity: 1;
}
/* .ssi:hover .active-url-indicator{
    opacity: 1;   
} */
.ssi:hover .active-url{
    /* display: block; */
    visibility: visible;
    opacity: 1;
}
.ssi:hover .passive-url{
    /* display: none; */
    visibility: hidden;
    opacity: 0;

}


.active-link{
    text-decoration: none;
}
.active-link .active-url-indicator{
    opacity: 1;
}
.active-link .active-url{
    /* display: block; */
    visibility: visible;
    opacity: 1;
}
.active-link .passive-url{
    /* display: none; */
    visibility: hidden;
    opacity: 0;
}




.side-menu a{
    display: block;
    text-decoration: none
}

.active-url-indicator{
    opacity: 0;
    width: 15px;
    height: 30px;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(-100%,-50%);
    border-radius: 5px 0 0 5px;
    background-color: #3A6FF8;
    transition: 0.4s;
}

