.description-content{
    width: 100%;
    min-height: 100vh;
    background-color: #fff
}
.description-title-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 50px 7px 30px;
    background-color:white;
    /* border-bottom: 1px solid  #dedede; */
    gap:15px;
    color: #0F044C;
}
.description-title-div h1{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
}

.description-inner-container{
    display: flex;
    background-color: #fff;
    height: 100%;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - 138.88px);
    
}
/* @media only screen and (max-width: 1024px)  {
    .description-inner-container{
        max-height: none;
    }
} */
.description-inner-content{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    /* width: 100%; */
    margin: 0 35px 35px 35px ;
    overflow-y: auto;
    padding:15px 25px 25px 25px;
    overflow-x: hidden;
    /* border: 1px solid #dedede; */
    border-radius: 10px;
    /* scroll-padding-top: 70px; */
}

/* .description-disabled-button{
    background-color: lightgrey;
    pointer-events: none;
} */

.current-description-wait-refresh{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -32px;
}
.current-description-wait-refresh-inner{
    position: fixed;
    top:30px;
    display:flex;
    align-items: center;
    font-size: 0.9rem;
    color: #b5b5b5
}

@media only screen and (max-width: 402px){
    .description-inner-content{
      margin: 15px 0;
  }
  }







