.collection-screenshot-select-div{
    display: flex;
    width: calc(100% - 425px);
    position: fixed;
    top: 35px;
    justify-content: flex-end;
    z-index: 4;
}
.collection-screenshot-select-local{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 150px;
    /* border-radius: 7px; */
    /* border: 1px solid grey; */
    padding: 5px 0px 5px 10px;
    z-index: 4;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
    border-radius: 10px;
}
.collection-screenshot-select-local-language{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 24px;
    min-width: max-content;
    color:#0f044c;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}
.collection-screenshot-select-local-icon{
    width: 15px;
    height: 15px;
    margin: 0 15px;
    transition:transform 0.4s ease-in-out;
}

.collection-opacity-div{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index:4;
    transition: visibility 0.45s, opacity 0.45s;
    visibility: hidden;
    opacity: 0;
    left:100%;
    /* top: 30px; */

}

.collection-arrow-up {
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid rgb(216, 216, 216);
    position: fixed;
    margin-top: 20px;
    margin-right: 74.5px;
    z-index: 3;
  }
  .collection-arrow-up2 {
    width: 0; 
    height: 0; 
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid rgb(255, 255, 255);
    position: fixed;
    margin-top: 20px;
    margin-right: 76px;
    z-index: 5;
  }

.collection-screenshot-local-list{

    max-height: 250px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #fff;
    min-width: 250px;
    position: fixed;
    margin-top: 32px;
    border-radius: 10px;
    border: 1px solid rgb(214, 214, 214);
    z-index: 4;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 15px, rgb(0 0 0 / 5%) 0px 0px 1px;
    color:#0f044c;
}

/* .screenshot-local-list-item{

} */

.collection-screenshot-local-list a{
    text-decoration: none;
}

.collection-screenshot-local-list-visible{
    visibility: visible;
    opacity: 1;
}
.collection-screenshot-local-list-rotate-icon{
    transform : rotateX(180deg);
}



.show-media-devices{
    padding: 15px 30px;
    font-size: 15px;
    color: #3A6FF8;
    font-weight: bold;
}
#showHideMedia{
    cursor: pointer;
}

.encap-media{
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 40px 0;
}

.hidden-devices{
    display: none;
}
.visible-devices{
    display: block;

}


