.Ask-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 51;
    

    display: flex;
    align-items: center;
    justify-content: center;
}
.Ask-collectionPopupBack{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000066;
    height: 100%;
    width: 100%;
    z-index: 52;

}
.Ask-popup-content{
    width: 600px;
    height: 450px;
    background-color: white;
    border-radius: 7px;
    position: relative;
    

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 53;
    gap:15px;
}
.Ask-popup-name{
    font-size: 1.5rem;
    font-weight: bold;
    color:#0f044c;
    margin-bottom: 15px;
}
.Ask-collection-form{
    width: 80%;
}
.Ask-popup-form{
    width: 100%;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 7px;
    padding: 10px;
}
.Ask-collectionPopupButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Ask-popup-button{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    /* margin-top: 35px; */
    font-size: 1rem;
    padding: .625em 1.1em;
    margin-right: 10px;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.Ask-popup-button2{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    /* margin-top: 35px; */
    font-size: 1rem;
    padding: .625em 1.1em;
    height: 45px;
    text-align: center;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.Ask-popup-button:hover{
    background-color: #305dd1;
}
.Ask-popup-button:active{
    background-color: #305dd1;
}
.Ask-popup-button2:hover{
    background-color: #dc7429;
}
.Ask-popup-button2:active{
    background-color: #4f565c;
}