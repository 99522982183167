.cbi{
    background-color: #3A6FF8;
    margin-right:15px;
    border-radius: 7px 7px 0 0;
    min-width: 160px;
    padding: 0 15px 0 10px;
    /* padding: 0 0 0 15px; */
    height: 40px;
    border:none;
    color:#fff;
    text-decoration: none;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 15%);
    -webkit-box-shadow:0px 0px 6px 0px rgb(0 0 0 / 15%);
    -moz-box-shadow:0px 0px 6px 0px rgb(0 0 0 / 15%);
}
/* .cbi:active{
    box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -webkit-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -moz-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
} */
/* .cbi:hover{
    background-color: #454555;
} */
.active-linkb{
    background-color: #fff;
    margin-right:15px;
    border-radius: 7px 7px 0 0;
    min-width: 160px;
    padding: 0 15px 0 10px;
    /* padding: 0 0 0 15px; */
    height: 40px;
    border:none;
    color: #0F044C;
    text-decoration: none;
    width: max-content;
    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    position: relative;


    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 15%);
    -webkit-box-shadow:0px 0px 6px 0px rgb(0 0 0 / 15%);
    -moz-box-shadow:0px 0px 6px 0px rgb(0 0 0 / 15%);
}

.collection-select-button{
    width: 100%;
    text-align: center;
    min-width: max-content;
    margin-right: 0;
}
.current-link{
    padding: 0 15px !important;
    background-color: #0F044C;
    color: #fff;
}
.colmenu{
    cursor: pointer;
    /* position: absolute;
    right: 1px;
    height: 18px; */
    /* padding-right: 8px;
    padding-left: 8px; */
}
/* .colmenu1{
    display: none;
}
.colmenu2{
    display: none;

} */

/* .active-linkb .colmenu1{
    display: block;
}
.cbi .colmenu2{
    display: block;
} */

/* collection tabs */


.collection-buttons{
    display: flex;
    align-items: center; 
    overflow-x: auto;
    padding: 20px 0px 0px 35px;
    background-color:#f5f5f5;
    position: relative;
}
.collection-select-button-add{
    background-color: #fff;
    margin-right:15px;
    border-radius: 7px 7px 0 0;
    width: 40px;
    height: 40px;
    border:none;
    color:white;
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 15%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.collection-select-button-add:hover{
    /* background-color:#9b9b9b; */
    cursor: pointer;
}
.collection-select-button-add:active{
    box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -webkit-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -moz-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
}
.collection-select-button-add:disabled{
    cursor: not-allowed;
}
.collection-buttons::-webkit-scrollbar {
    height: 7px;
}
.collection-buttons::-webkit-scrollbar-thumb {
    background-color: #ccccccbd;
    border-radius: 7px;
    visibility: hidden;  
}
.collection-buttons::-webkit-scrollbar-track {
    background-color: #f5f5f5;   
}
.collection-buttons:hover::-webkit-scrollbar-thumb{
    visibility: visible;
}
/* Hide scrollbar for Chrome, Safari and Opera
.collection-buttons::-webkit-scrollbar {
    display: none;
} */

