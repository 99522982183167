.billing-title-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:15px;
    flex-wrap: wrap;
    background-color: white;
    padding: 25px 50px 25px 35px;
    color:#0f044c
}
.billing-title-div h1{
    margin:0;
    font-size: 24px;
}
.billing-content{
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    
}
.billing-inner-container{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    gap:35px;
    /* flex-wrap: wrap; */
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 85.88px);
    padding: 0 35px 35px 35px;
    overflow: auto;
}
.billing-info-div{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    padding: 35px;
}
.billing-sub{
    margin-bottom: 0;
    margin-top: 35px; 
    font-weight: 700;
    font-size: 24px;
    color: #0f044c;
}
.billing-info-div h2{
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: #0f044c;
}
.quota-data{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.manage-sub{
    color: white;
    background-color: #3A6FF8;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0 1.1em;
    width: max-content;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;

}

.upgrade-table{
    /* background-color: #fff; */
    border-radius: 15px;
    color: #0f044c;
    /* padding: 25px; */
}
.upgrade-list{
    list-style:none;
}
.upgrade-list li{
    margin:35px 0;
    font-size: 1.1rem;
    display: flex;
    width: max-content;
    justify-content: center;
    align-items: center;
    gap:15px
}
/* .price-table{ */
    /* background-color: #fff; */
    /* border-radius: 15px; */
    /* padding: 25px; */
/* } */

.switch-price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background: #f5f5f5;
    margin: 0 auto;
    margin-bottom: -20px;
    /* margin-top: 15px; */
}
.switch-price .choicePrice{
    color: #a7a7a7;
}
.price-table {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    margin: 0 auto;
    height: 810px;
    /* max-height: 800px; */
    max-width: 1375px;
    flex-wrap: wrap;
  }
.table13{
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
    flex:1;
    height: 100%;
}
.table13-inner{
    background-color: #ffffffa3;
    /* padding: 35px; */
    display: flex;
    flex-direction: column;
    height: 95%;
    padding: 35px;
}
.table-btn-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.table-btn-div button{
    color: white;
    background-color: #3A6FF8;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.825em 1.1em;
    margin-top: 20px;
    min-width: max-content;
    width: 100%;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.free-btn{
    background-color: #eee !important;
    cursor:default !important;
    color:#000 !important;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1) !important;
}
.table-btn-div span{
    font-size: 0.9rem;
    color: #676767;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 700;
}
.table-desc-div{
    display: flex;
    flex-direction: column;
}
.table-desc-div span{
    color: #676767;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: justify;
    margin: 10px 0;
    line-height: 24px;
}
.table-desc-div hr{
   width: 100%;
   border:1px solid #ddd;
}
.table-title-div{
    display: flex;
    align-items: center;
    gap: 5px;
}
.table h3{
    margin: 0;
    color:#0f044c;
}
.table ul{
    margin: 0;
    margin-top: 10px;
    padding: 0;
}
.table ul li{
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    gap: 10px;
}
.table ul li span{
    font-size: 1rem;
    font-weight: 700;
    color:#676767;
    line-height: 24px;
}

.table2{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex:1;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
    height: 100%;
    padding: 35px;
    z-index: 2;
}
.table-price{
    color: #0f044c;
}
.table-price-span{
    font-size: xxx-large;
    font-weight: 700;
}
.table{
    min-width: 343px;
}

 