.screenshot-content{
    width: 100%;
    min-height: 100vh;
    background-color: #fff
}
.screenshot-title-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 50px 7px 30px;
    background-color:white;
    /* border-bottom: 1px solid  #dedede; */
    gap:15px;color: #0F044C;
}
.screenshot-title-div h1{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
}
/* .screenshot-update-button{
    background-color: #3A6FF8;
    margin-left: 25px;
    border-radius: 7px;
    width: 137px;
    height: 34px;
    border:none;
    color:white; */
    /* transition: 0.4s; */
/* }
.screenshot-update-button:hover{
    background-color:#008cde;
}
.screenshot-update-button:active{
    box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -webkit-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -moz-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
} */
.screenshot-inner-container{
    display: flex;
    background-color: #fff;
    height: 100%;
    width: 100%;
    
    max-height: calc(100vh - 138.88px);
}
/* @media only screen and (max-width: 1024px)  {
    .screenshot-inner-container{
        max-height: none;
    }
} */
.screenshot-inner-content{
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5; */
    width: 100%;
    margin: 0 35px 35px 35px;
    overflow-y: auto;
    /* padding: 0 25px 25px 25px; */
    overflow-x: hidden;
    /* border: 1px solid #dedede; */
    border-radius: 10px;
    position: relative;
    /* gap: 10px;*/
}

/* .screenshot-disabled-button{
    background-color: lightgrey;
    pointer-events: none;
} */

/* .screenshot-collection-buttons{
    display: flex;
    align-items: center;
       
}
.screenshot-collection-select{
    padding: 0 50px 00px 35px;
}

.screenshot-collection-select-button-add{
    background-color: rgb(204, 204, 204);
    margin-right:15px;
    border-radius: 7px 7px 0 0;
    width: 25px;
    height: 34px;
    border:none;
    color:white;
}
.screenshot-collection-select-button-add:hover{
    background-color:#9b9b9b;
    cursor: pointer;

}
.screenshot-collection-select-button-add:active{
    box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -webkit-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
    -moz-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.29) inset;
} */

