.owner-screenshot-previews{
    width: 100%;
    background-color: #fff;
    border-radius: 0 10px 10px 10px;
    padding: 15px 25px 25px 25px;
    margin-bottom: 20px;

}
.owner-screenshot-previews-inner{
    display: flex;
    overflow: auto;
    overflow: overlay;
    gap: 25px;    
    padding: 14px 0 25px 0;
}

.previewOwner{
    border-radius: 7px 0 0 7px !important;
}
.mm{margin-bottom: 20px;}
.screenshotOwner{
    border-radius: 0 7px 7px 0 !important;
}

.current-owner-screenshot-item{
    display: flex;
    flex-direction: column;
}
.current-owner-screenshot-device-name{
    margin-bottom: 15px;
    /* font-weight: bold; */
    line-height: 1;
}
.current-owner-screenshot-set{
    width: 100%;
    /* background-color: #f2f2f2; */
    /* padding: 25px; */
    /* border-radius: 7px 7px 7px 7px; */
    display: flex;
    flex-direction: column;
    /* min-height: 274px; */
    justify-content: space-between;
    height: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.20) 3px 6px 6px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.20) 3px 6px 6px;
    -moz-box-shadow: rgba(0, 0, 0, 0.20) 3px 6px 6px; */
}



.current-owner-screenshot-field{
    display: flex;
    /* flex-wrap: wrap; */
    overflow-x: auto;
    overflow-x: overlay;
    overflow-y: hidden;
    padding-left: 5px;
    padding-top: 5px;
    margin-top: auto;
    gap: 15px;

}

.current-owner-screenshots{
    max-height: 216.43px;
    min-height: 216.43px;
    height: 216.43px;
    /* margin-right: 25px; */
    border-radius: 7px;
    transition: 0.4s;
    width: auto;
    box-shadow: 0px 2px 6px 0px rgb(192 192 192 / 70%);

}
.current-owner-screenshots:hover{
    opacity: 0.6;
    cursor: pointer;
}
.current-owner-opacity{
    opacity: 0.7;
}
.owner-col{
    position: relative;
    /* max-height: 216.43px; */
    min-height: 216.43px;
    min-width: 125px;
}





.owner-modal {
    display: none;
    position: fixed;
    z-index:50;
    padding: 10px 62px 0px 62px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000000b3;
}
.owner-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin: auto;
    padding: 0 0 0 0;
    width: 80%;
    max-width: 1200px;
    min-height: 90vh;
}
.current-owner-screenshot-slide-device-name{
    text-align: center;
    color: #fff;
}

.current-owner-screenshots-slide{
    height: auto;
    width: 300px;
    border-radius: 7px;
    margin: 0 auto;
}
.owner-slide-style{
    text-align: center;
}
.owner-close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}
.owner-close:hover,
.owner-close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}
.owner-previous,
.owner-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.4s ease;
  border-radius: 7px;
  user-select: none;
  -webkit-user-select: none;
}

.owner-next {
  right: 20%;
}
.owner-previous {
  left: 20%;
}

.owner-previous:hover,
.owner-next:hover {
  background-color: rgb(126, 126, 126);
}
.owner-previous:active,
.owner-next:active {
  background-color: #3A6FF8;
}



@media only screen and (max-width: 1157px){
    .owner-screenshot-previews {
        border-radius: 0 0 10px 10px;
    }
}