.emailConfirmation-inner-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-size: 1.2rem;
}
.emailConfirmation-inner-content h1{
    font-size: 4rem;
}
.emailConfirmation-inner-content a{
    color: #3A6FF8;
    text-decoration: none;
}
.emailConfirmation-error{
    color: red;
}