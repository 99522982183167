.profile-title-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:15px;
    flex-wrap: wrap;
    background-color: white;
    padding: 25px 50px 25px 35px;
    color:#0f044c
}
.profile-title-div h1{
    margin:0;
    font-size: 24px;
}
.profile-content{
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    
}
.profile-inner-container{
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    gap:35px;
    /* flex-wrap: wrap; */
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 119.88px);
    padding: 35px;
    overflow: auto;
}
.profile-info-div{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px;
}
.profile-info-div h2{
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: #0f044c;
}
.profile-info{
    font-weight: bold;
    color: #0f044c;
}
.profile-form-div{
    display: flex;
    gap: 35px;
    flex-wrap: wrap;
}
.profile-pass-page-form{
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 0;
    min-width: 360px;
    flex: 1;
   
}
.form1{
    min-height: 357.4px;
}
.form2{
    min-height: 435px;
}
.profile-pass-page-form h2{
    padding: 0 25px 25px 25px;
    margin: 0;
    font-size: 20px;
    color: #0f044c;
}
.profile-pass-page-form h3{
    padding: 0 25px 25px 25px;
    margin: 0;
    font-size: 16px;
    color: #0f044c;
    float: left;
}
.profile-pass-page-form select{
    margin: -10px 25px 25px 0;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
    color: #0f044c;
    padding: 5px;
    border-radius: 10px;
    outline: none;
}


.profile-pass-page-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 51;
    

    display: flex;
    align-items: center;
    justify-content: center;
}
.profilePpassPopupBackPage{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 52;

}
.profile-pass-page-popup-content{
    width: 600px;
    height: 300px;
    background-color: white;
    border-radius: 7px;
    position: relative;
    

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
.profile-pass-page-popup-name{
    font-size: 1.5rem;
    font-weight: bold;
    color:#0f044c
}
.profile-pass-page-popup-form{
    width: 100%;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 7px;
    padding: 10px;
}
.profile-pass-page-popup-role{
    width: 100%;
    margin-top: 15px;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 7px;
    padding: 10px;
}
.profilePpassPopupButtons{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 25px;
    align-items: center;
}
.profile-pass-page-popup-button{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    margin-right: 10px;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.profile-pass-page-popup-button2{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    text-align: center;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.profile-pass-page-popup-button:hover{
    background-color: #305dd1;
}
.profile-pass-page-popup-button:active{
    background-color: #305dd1;
}
.profile-pass-page-popup-button2:hover{
    background-color: #dc7429;
}
.profile-pass-page-popup-button2:active{
    background-color: #4f565c;
}


.delete-account-button{
    align-self: flex-end;
    min-width: max-content;
    width: max-content;
    color: white;
    border-radius: 10px;
    background-color: #0f044c;
    border: none;
    cursor: pointer;
    margin-top: 35px;
    font-size: 0.85rem;
    padding: 0.625em 1.1em;
    margin-right: 10px;
    height: 45px;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}

.appSumoCodes{
    padding: 25px 25px 0 25px;
    display: flex;
    flex-direction: column;
}
.appSumoCodes h3{
    margin: 25px 0 0 0;
    color: #0f044c;
}
.codeArea{
    display: flex;
    flex-wrap: wrap;
    
}