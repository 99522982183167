.owner-upload-button{
  color: white;
  border-radius: 10px;
  background-color: #3A6FF8;
  border: none;
  cursor:pointer;
  font-size: 1rem;
  /* padding: .625em 1.1em; */
  width: 180px;
  /* margin-top: 15px; */
  height: 36px;
  background-color: #71c456;
}
.owner-upload-button:hover{
  background-color: #3A6FF8e6;
}
.owner-upload-button:active{
  background-color: #3A6FF8;
}
.owner-upload-button:disabled{
  cursor: not-allowed;
}


.owner-import-button,.owner-export-button{
  color: white;
  border-radius: 10px;
  background-color: #3A6FF8;
  border: none;
  cursor:pointer;
  font-size: 1rem;
  /* padding: .625em 1.1em; */
  width: 90px;
  height: 36px;
}
/* .owner-import-button{margin-right: 10px;} */
.owner-import-button:hover,.owner-export-button:hover{
  background-color: #3A6FF8e6;
}
.owner-import-button:active,.owner-export-button:active{
  background-color: #3A6FF8;
}
.owner-import-button:disabled,.owner-export-button:disabled{
  cursor: not-allowed;
}



.owner-select-div{
    display: flex;
    width: calc(100% - 425px);
    min-width: 355px;
    justify-content: flex-end;
    position: fixed;
    top:35px;
    z-index: 4;
}
.owner-select-local{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  /* border-radius: 7px; */
  /* border: 1px solid grey; */
  padding: 5px 0px 5px 10px;
  z-index: 4;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
  border-radius: 10px;
}
.owner-select-local-language{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 24px;
    min-width: max-content;
    color:#0F044C;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}
.owner-select-local-icon{
    width: 15px;
    height: 15px;
    margin: 0 15px;
    transition:transform 0.4s ease-in-out;
}

.owner-opacity-div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index:4;
    transition: visibility 0.45s, opacity 0.45s;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left:100%

}

.owner-arrow-up {
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid rgb(216, 216, 216);
    position: fixed;
    margin-top: 20px;
    margin-right: 74.5px;
    z-index: 1;
  }
  .owner-arrow-up2 {
    width: 0; 
    height: 0; 
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid rgb(255, 255, 255);
    position: fixed;
    margin-top: 20px;
    margin-right: 76px;
    z-index: 3;
  }

.owner-local-list{

    max-height: 250px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #fff;
    min-width: 250px;
    position: fixed;
    margin-top: 32px;
    border-radius: 10px;
    border: 1px solid rgb(214, 214, 214);
    z-index: 2;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 15px, rgb(0 0 0 / 5%) 0px 0px 1px;
    color:#0F044C;
}

/* .screenshot-local-list-item{

} */

.owner-local-list a{
    text-decoration: none;
}

.owner-local-list-visible{
    visibility: visible;
    opacity: 1;
}
.owner-local-list-rotate-icon{
    transform : rotateX(180deg);
}



  .tab {
    display: flex;
    /* margin-top: 15px; */
    gap: 1px;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: #3A6FF8;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.65em 1.1em;
    /* transition: 0.3s; */
    font-size: .85rem;
    font-weight: 500;
    border-radius: 7px 7px 0 0;
    margin-right: 7px;
    color: white;
    /* box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 15%); */
  }
  
  /* Change background color of buttons on hover */
  /* .tab button:hover {
    background-color: #ddd;
  } */
  
  /* Create an active/current tablink class */



  .tab button.active {
    background-color: #fff;
    color: black;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }

  .no-screenshots-collections{
    display: flex;
    /* background-color: #f2f2f2; */
    /* padding:25px; */
    font-weight: lighter;
    border-radius: 7px 7px 7px 7px;
    min-height: 223px;
    min-width: 190px;
    /* box-shadow: rgb(0 0 0 / 20%) 3px 6px 6px;
    -webkit-box-shadow: rgb(0 0 0 / 20%) 3px 6px 6px;
    -moz-box-shadow: rgba(0, 0, 0, 0.20) 3px 6px 6px; */
  }

  @media only screen and (max-width: 1157px){
    .tab {
      flex-direction: column;

    }
    .tab button{
        margin-right:0;
        margin-left:0

    }
    .no-screenshots-collections{
      border-radius: 0 0 7px 7px;
    }
    .tab button.active {
      background-color: #F5F5F5;
  
      box-shadow: 3px 7px 6px 0px rgb(0 0 0 / 0%);
      -webkit-box-shadow: 3px 7px 6px 0px rgb(0 0 0 / 0%);
      -moz-box-shadow: 3px 7px 6px 0px rgb(0 0 0 / 0%);
    }
    
}
  @media only screen and (max-width: 1005px){
    /* .no-screenshots-collections{
      border-radius: 0 0 7px 7px;
    } */
  }