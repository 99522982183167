.login-container{
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}
.login-container-left{
    background-image: url("/public/assets/Images/hero@2x.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-basis: 50%;
}
.login-container-right{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-basis: 50%;
}
.login-right-formContainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin:30px 0;
}
.title-div-submitted{
    text-align: center;
}
.title-div-submitted p{
    font-weight: 500;
    color: #a5a5a5;
}
.title-div-submitted a{
    color: #3A6FF8;
    text-decoration: none;
}

.mail-button-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
  
@media only screen and (max-width: 500px) {
    .mail-button-container{
        margin-top: 20px;
        width: 100%;
        flex-direction: row;
    }
}
  
.title-div{
    text-align: center;
    line-height: 1;
}
.title-div p{
    font-weight: 500;
    color: #a5a5a5;
    line-height: normal;
}
.title-div a{
    color: #3A6FF8;
    text-decoration: none;
}
.signup-form{
    width: 100%;
    min-width: 320px;
    max-height: 800px;
    max-width: 1000px;
}
.form-input{
    border-radius: 6px;
    background-color: #f2f2f2;
    height: 46px;
    width: 100%;
    outline: none;
    border: none;
    padding: 20px 20px;
}
.form-group{
    display: flex;
    justify-content: space-evenly;
    flex-basis: 100%;
}
.form-group-inner{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px 25px;
    width: 100%;
}
.form-group-inner label {
    font-weight: 600;
    color: #0f044c;
    margin: 5px 0 10px 0;
}
.password-strength-meter{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 25px;
    flex-basis: 100%;
}
.meter{
    width: 20%;
    height:5px;
    background-color: #a5a5a5;
    border-radius: 10px;
    margin:0 3px;
}
.valid {
    background-color: #007acc;
  }
.all{
    background-color: #06a303 !important;
}
.password-message{
    margin: 9px 25px 8px 25px;
    flex-basis: 100%;
    color:#a5a5a5;
}
.form-accept-terms{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px 25px;
    flex-basis: 100%;
}
.form-accept-terms-inner{
    display: flex;
    flex-direction: row;
}
.form-accept-terms label{
    color: #a5a5a5;
    margin-top:3px;
}
.checkbox-input[type="checkbox"]{
    width: 25px;
    height: 25px;
    /* background-color only for content */
    margin-right: 15px;
    cursor: pointer;
}
.form-button-group{
    margin: 16px 25px;
    flex-basis: 100%;
}
.form-button{
    height: 42px;
    width: 100%;
    border: none;
    color: white;
    border-radius: 5px;
    background-color: #3A6FF8;
    transition: 0.2s;
    cursor: pointer;
}
.form-button:hover{
    background-color: #0089d8;
}
.form-name-error{
    margin: 0px 25px;
    width: 100%;
}
.error-message{
    color:red !important;
    font-size: 0.8rem;
    font-weight: 600;
    margin:5px 0 !important;
}


@media screen and (max-width: 1080px) {
    .form-group.form-flag{
        flex-direction: column !important;
    }
    .form-group.form-flag .form-group-inner{
        width: auto !important;
    }
}