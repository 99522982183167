.video-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 51;
    

    display: flex;
    align-items: center;
    justify-content: center;
}
.videoPopupBack{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000066;
    height: 100%;
    width: 100%;
    z-index: 52;

}
.video-popup-content{
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 7px;
    
    position: relative;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
