
.appInfo-content{
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
}
.app-title-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
    padding: 25px 50px 25px 35px;

}
.app-info-icon{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.app-info-icon h1{
    font-size: 1.5rem;
    color: #0f044c;
}
.app-info-icon img{
    width: auto;
    height: 50px;
    margin-right: 15px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    border-radius: 7px;
}
.app-data{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;
    gap: 25px;
}
.app-data div{
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.app-data p {
    margin: 0 15px 0 0;
    color: #0f044c;
}

.appInfo-inner-container{
    display: flex;
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 119.88px);
}
.appInfo-inner-content{
    display: flex;
    flex-direction: column;
    gap:35px;
    /* background-color: white; */
    width: 100%;
    /* margin: 35px; */
    overflow-y: auto;
    padding: 35px;
    /* border: 1px solid #dedede; */
    border-radius: 10px;
    position: relative;
}
.appInfo-inner-content h2{
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #0f044c;
}
.add-member{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.add-member-button{
    color: white;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    font-size: 0.85rem;
    padding: .625em 1.1em;
    width: max-content;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }
  .add-member-button:hover{
    background-color: #3A6FF8e6;
  }
  .add-member-button:active{
    background-color: #3A6FF8;
  }
  .add-member-button:disabled{
    cursor: not-allowed;
  }

  .delete-app-button{
    color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    font-size: 0.85rem;
    padding: .625em 1.1em;
    width: max-content;
    margin-left: 10px
  }
  .delete-app-button:hover{
    background-color: #F3802Dc6;
  }
  .delete-app-button:active{
    background-color: #F3802D;
  }
  .delete-app-button:disabled{
    cursor: not-allowed;
  }


  @media only screen and (max-width: 1008px){
    .appInfo-inner-content h2{
      margin: 0 0 15px 0;
  }
  }

  @media only screen and (max-width: 401px){
    .delete-app-button{
      margin-left: 0;
  }
  }

  @media only screen and (max-width: 402px){
    .appInfo-inner-content{
      margin: 15px 0;
  }
  }