.owner-description-item{
    margin-bottom: 20px;
}
.owner-description-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.owner-section-name{
    display: flex;
    align-items: center;
    /* background-color:#F2F2F2 ;
    padding: 0 5px 0 5px;
    border-radius: 7px; */
}
.owner-name{
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;

}
.owner-description-textarea{
    background-color: #fff;
    border: none;
    border-radius: 7px;
    outline: none;
    padding: 20px 30px;
    max-height: 300px;
    max-width:100%;
    min-width:100%;
    overflow: auto;
}