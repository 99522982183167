
.upload-popup-content{
    position: fixed;
    bottom:35px;
    right: 35px;

    background-color: white;
    border-radius: 7px;
    padding: 10px;
    font-size: 0.8rem;
    z-index: 53;

    max-height: 520px;
    min-height: 120px;

    box-shadow: 0px 0px 21px 2px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 21px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 21px 2px rgba(0,0,0,0.2);

    transition: 0.5s;
}

.upload-popup-content button{
    background-color: #31c200;
    border: none;
    border-radius: 4px;
    height: 36px;
    color: #fff;
    cursor: pointer;
    position: relative;
    margin-top: 5px;
}
.upload-popup-content button:hover{
    background-color: #31c200e6;
}
.upload-popup-content button:active{
    background-color: #31c200;
    box-shadow: rgb(0 0 0 / 17%) 0px 23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
    -webkit-box-shadow: rgb(0 0 0 / 17%) 0px 23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
    -moz-box-shadow: rgb(0 0 0 / 17%) 0px 23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset;
}

.intoView-uploadPopup{
    transform: translateY(calc(100% + 35px));

}

#toggle-upload-popup{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-20px);
    background-color: white;
    border-radius: 7px 7px 0 0;
    padding: 3px;
    -webkit-box-shadow: 0px -6px 10px 0px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px -6px 10px 0px rgb(0 0 0 / 15%);
    box-shadow: 0px -6px 10px 0px rgb(0 0 0 / 15%);
}

#toggle-upload-popup-icon{
    width: 15px;
    height: 15px;
    transition: transform 0.2s ease-in-out;
    margin: 0 15px;
    transform: rotateX('180deg');
}

.textUpload-inner-content{
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 0 10px;
    max-height: 500px;
    min-height: 100px;
    overflow:auto;
}

.upload-popup-content>span{
    margin: 5px 0;
}
.upload-popup-content>button{
    margin: 5px 0;
}
.loading-bar{
    background-color: lightgray;
    border-radius: 7px;
    width: 100%;
    height: 5px;
    text-align: left;
}
.loading-bar-ongoing{
    height: 5px; 
    border-radius: 7px;
    background-color:#3A6FF8 ;
}
.loading-bar-finished{
    border-radius: 7px;
    background-color: green;
    height: 5px;
}

.upload-single{
    display: flex;
    flex-direction: column;
    gap:5px;
    margin: 15px 0;
    border-radius: 7px;
    /* border-top: 1px solid #ddd; */
    /* border-bottom: 1px solid #ddd; */
    /* border: 1px solid #ddd; */
    /* padding: 10px 0; */
    padding: 15px;
    /* text-align: justify; */
    line-height: 20px;
    background-color: #f5f5f5;
}
.upload-title{
    font-size: 1rem;
}
.upload-subtitle{
    font-size: .9rem;
    margin-top: 5px;
}


