.homePage-container{
    min-height: 100vh;
    transition: 0.4s;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 450px;
    margin-left: 360px;
    transition: 0.4s;
}

