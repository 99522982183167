.summary-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 51;

    display: flex;
    align-items: center;
    justify-content: center;
}
.summaryPopupBack{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000066;
    height: 100%;
    width: 100%;
    z-index: 52;
}
.summary-popup-content{
    width: 90%;
    height: 80vh;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
    max-width: 800px;
    min-height: 400px;
    min-width: 320px;
    position: relative;

}
.summary-popup-name{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    
    /* Dark */
    
    color: #0F044C;
}
.summary-div{
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 16px 30px;
    overflow: auto;
    width: 95%;
    height: 60%;
}
.summaryHeading{
    font-weight: bold;
    font-size: 1.3rem;
}
.summarySubHeading{
    font-weight: bold;
    margin-left: 15px;
    font-size: 1rem;
}
.summaryP{
    font-size: 0.8rem;
    margin:0 30px;
}
.summaryPopupButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.summary-popup-button2{
    width:15%;
    color: white;
    border-radius: 10px;
    background-color: #3a6ff8;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    text-align: center;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.summary-popup-button2:hover {
    background-color: #dc7429;
}
.summary-popup-button2:active{
    background-color: #dc7429;
}


