.forgot-pass-page-popup-container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px;
}

.forgot-pass-page-popup-content{
    width: 600px;
    background-color: white;
    border-radius: 7px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 53;
}
.forgot-pass-page-popup-name{
    font-size: 1.5rem;
    font-weight: bold;
    color:#0f044c
}
.forgot-pass-page-form{
    width: 80%;
}
.forgot-pass-page-popup-form{
    width: 100%;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 7px;
    padding: 10px;
}
.forgot-pass-page-popup-role{
    width: 100%;
    margin-top: 15px;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    border-radius: 7px;
    padding: 10px;
}
.forgotPpassPopupButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgot-pass-page-popup-button{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    margin-right: 10px;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.forgot-pass-page-popup-button2{
    width: 20%;
    color: white;
    border-radius: 10px;
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    text-align: center;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.forgot-pass-page-popup-button:hover{
    background-color: #305dd1;
}
.forgot-pass-page-popup-button:active{
    background-color: #305dd1;
}
.forgot-pass-page-popup-button2:hover{
    background-color: #dc7429;
}
.forgot-pass-page-popup-button2:active{
    background-color: #4f565c;
}