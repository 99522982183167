.import-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #00000066; */
    height: 100%;
    width: 100%;
    z-index: 51;
    

    display: none;
    align-items: center;
    justify-content: center;
}
.importPopupBack{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000066;
    height: 100%;
    width: 100%;
    z-index: 52;

}
.import-popup-content{
    width: 85vw;
    height: 85vh;
    background-color: white;
    border-radius: 7px;
    
    position: relative;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
.import-popup-content-pre{
    width:550px;
    height: 200px;
    background-color: white;
    border-radius: 7px;
    

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
.import-popup-content-pre-2{
    width:500px;
    height: 430px;
    background-color: white;
    border-radius: 7px;
    

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 53;
}
.import-popup-name{
    font-size: 1.5rem;
    font-weight: bold;
    color:#0f044c;
}
.create-import-form{
    display: flex;
    flex-direction: column;
    width: 90%;
    max-height: 75%;
    min-height: 75%;
}
.create-import-form-inner{
    padding:15px ; 
    border-radius: 7px;
    background-color: #f5f5f5;
    overflow: auto;
    height: 100%;
}

.selectTeplateTitle{
    font-size: 1.1rem;
    /* font-weight: bold; */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.languageDropdown{
    margin-bottom: 10px;
}
.css-1xc3v61-indicatorContainer{
    color: black !important;
}
.selectedLanguage{
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    margin-bottom: 2px;
}
.selectedLanguage2{
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    margin-bottom: 10px;
}
.selectedLangguage2inner{
    display: flex;
    align-items: center;
    margin-bottom: 5px; 
}
.selectedLanguageDelete{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    color: white;
    background-color: red;
    margin-left: 5px;
    cursor: pointer;
    width: 15px;
    height: 15px;
    font-weight: bold;
}
.selectedDevice{
    display: flex;
    align-items: center;
    margin: 1px 0 1px 30px;
}
.importPopupButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
}
.importPopupButtons2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
}
.import-popup-button{
    width: 15%;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    margin-right: 10px;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.import-popup-button2{
    width:15%;
    color: white;
    /* border-radius: 10px; */
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    margin-top: 35px;
    font-size: 1rem;
    padding: .625em 1.1em;
    text-align: center;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}



.import-popup-button-pre{
    width: 15%;
    color: white;
    border-radius: 10px;
    background-color: #3A6FF8;
    border: none;
    cursor:pointer;
    font-size: 1rem;
    padding: .625em 1.1em;
    margin-right: 10px;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}
.pre1{
    margin-left: 15%;
}
.pre2{
    background-color:red;
    margin-right: auto;
}
.pre11{
    
}
.pre2{
    background-color:red;
    margin-right: auto;
}
.import-popup-button2-pre{
    width:15%;
    color: white;
    border-radius: 10px;
    background-color: #F3802D;
    border: none;
    cursor:pointer;
    margin-right: 15%;

    font-size: 1rem;
    padding: .625em 1.1em;
    text-align: center;
    height: 45px;box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
}


.import-popup-button:hover{
    background-color: #305dd1;
}
.import-popup-button:active{
    background-color: #305dd1;
}
.import-popup-button2:hover {
    background-color: #dc7429;
}
.import-popup-button2:active{
    background-color: #4f565c;
}


.uploadTemplateDiv{
    position: relative;
    padding: 15px;
    height: 100%;
    width: 100%;
    border: 2px dashed #8b726079;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dragDiv{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(2px);
    
}
