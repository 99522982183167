.inner-content{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    min-height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
    gap:30px;
    /* position: relative;
    container-type: inline-size;
    container-name: inner; */
}

.welcome-message{
    font-weight: 700;
    font-size: 16.5px;
    line-height: 28px;
    color: #676767;
}

.inner-content h1{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    margin: 0;
    color: #3A6FF8;

}
.inner-content h2{
    font-size: 1.5rem;
    margin: 0
}

.white-div{
    padding: 31px 40px;
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    gap:30px;
    flex-wrap: wrap;
}
.your-apps{
    margin: 0 36px 36px 36px;
    padding: 33px 46px;
    background-color: #fff;
    
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    gap:28px;

}
.your-apps h2{
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color:#0f044c;
}
.search-app{
    border: none;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 12px 25px;
    outline: none;
    width: 300px;
    
}
.welcome-apps{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:24px;
}
.welcome-app{
    padding: 2px 20px 2px 0;
    border-radius: 15px;
    background-color: #fff;
    min-width: 400px;
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.4s;
    text-decoration: none;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.welcome-app:hover{
    scale: 1.05;
}
.shadow{
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
}

.addApp{transition: 0.4s;width: 75px;height: auto; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);border-radius: 15px;margin: 28px 0;}
.addApp:hover{
    scale: 1.3;
}


@media only screen and (max-width: 900px){
    .welcome-app span{
        font-size: 15px !important;
    }
    .welcome-app{
        min-width: 100%;
    }
}

@media only screen and (max-width: 402px){
    .content{
      min-width: 360px;
  }




}